.tablecontrols_container_row {
    display: flex;
    flex-grow: 1;
}

.pg_refreshdownload {
    display: flex;
    flex-direction: row;
    align-items: flex-end;
}

.tablecontrols_container_row > div:first-of-type {
    margin-left: auto;
}

.tablecontrols_container_row div:not(:first-of-type) {
    margin-left: 4px;
}

.tablecontrols_search_container {
    margin-top: 5px;
}

@media only screen and (max-width: 650px) {
    .tablecontrols_container_row {
        justify-content: flex-end;
    }

    .pg_refreshdownload {
        padding-bottom: 2%;
    }

    .pg_group {
        padding-bottom: 2%;
      }
}