
#crd_org_individuals_individuals td.ACTIVE {
    color: #45BA30;
}

#crd_org_individuals_individuals td.INVITED,
#crd_org_individuals_individuals td.AUTHENTICATING {
    color: #ff9e16;
}

#crd_org_individuals_individuals td.DECLINED {
    color: #d91e18;
}

#crd_org_individuals_individuals td.INACTIVE {
    color: #c8c9c9;
}