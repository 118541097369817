
#mdl_confirmation {
    width: 400px !important;
}

#mdl_confirmation .modal-content {
    padding: 32px 16px;
    text-align: center;
}

#mdl_confirmation .content-filter {
    padding-top: 16px;
}

#confirmationerror {
    display: flex;
    flex-direction: row;
}

#confirmationerror .cause{
    text-align: left;
}

/*  media queries --------------------------------------------------------------------------------------------------- */

@media only screen and (max-width: 450px) {
    #mdl_confirmation {
        width: 25rem !important;
    }
}

@media only screen and (max-width: 370px) {
    #mdl_confirmation {
        width: 20rem !important;
    }
}