button.download_button {
    width: 40px;
}

#btn_download {
    margin-left: 4px !important;
}

button.download_button.download_not_ready {
    opacity: 0.5;
}

button.download_button.loading {
    background-color: transparent !important;
}

button.download_button.loading:after {
    border-color: #2ea3a6 transparent transparent !important;
}