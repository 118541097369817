
.pg_result {
  justify-content: flex-end;
}

.pg_showing {
  display: flex;
  flex-direction: row;
}

.pg_paginationelements {
  display: flex;
}

.pg_showing_onerow {
  flex-direction: row !important;
}

@media only screen and (max-width: 650px) {

  .pg_result {
    padding-right: 0;
  }

  .pg_show {
    display: none;
  }
  .pg_showing {
    flex-direction: column;
  }

  .pagination_cmp{
    padding-top: 10rem;
  }

}