
#mdl_org_individuals_indv #tab_details {
    display: flex;
    flex-direction: row;
    font-size: 13px;
}

#mdl_org_individuals_indv #tab_details .profile {
    padding: 40px 16px 32px 16px;
    width: 50%;
    display: flex;
}

#mdl_org_individuals_indv #tab_details .code {
    width: 100px
}

#mdl_org_individuals_indv #tab_details .city {
    width: 250px;
}

#mdl_org_individuals_indv #tab_details .province {
    width: 150px;
}

#mdl_org_individuals_indv #tab_details .options {
    padding: 40px 16px 32px 16px;
    width: 50%;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
}

#mdl_org_individuals_indv #tab_details form {
    padding: 0 24px;
    margin-top: 40px;
    width: 100%;
}

#mdl_org_individuals_indv #tab_details .permission_id {
    width: 100%;
}

#mdl_org_individuals_indv #tab_status {
    padding: 16px 16px 32px 16px;
    width: 400px;
    margin: 0 auto;
}


#mdl_org_individuals_indv .statuswrapper {
    padding: 0 24px;
}

#mdl_org_individuals_indv .statusprogress {
    display: flex;
    flex-direction: row;
}

#mdl_org_individuals_indv .statusprogress-item {
    display: flex;
    flex-direction: column;
    flex: 1;
}

#mdl_org_individuals_indv .statusprogress-item-text {
    font-size: 11px;
    font-weight: 500;
    color: #cfd3d5;
    padding: 0 8px;
    text-align: center;
}

#mdl_org_individuals_indv .statusprogress-item-bar {
    border-left: 1px dashed rgba(255,255,255,0.7);
    height: 24px;
    width: 100%;
    background-color: #f1f2f2;
    display: flex;
    align-items: center;
    justify-content: center;
}

#mdl_org_individuals_indv .statusprogress-item-bar img {
    width: 16px;
    height: 16px;
    opacity: .8;
}

#mdl_org_individuals_indv .statusprogress-item-bar.invited {
    border-left: none;
    border-radius: 4px 0 0 4px;
}

#mdl_org_individuals_indv .statusprogress-item-bar.inactive,
#mdl_org_individuals_indv .statusprogress-item-bar.active,
#mdl_org_individuals_indv .statusprogress-item-bar.inviteexpired,
#mdl_org_individuals_indv .statusprogress-item-bar.declined {
    border-radius: 0 4px 4px 0;
}

#mdl_org_individuals_indv .statusprogress-item-bar.inactive-user,
#mdl_org_individuals_indv .statusprogress-item-bar.active-user {
    border-radius: 4px 4px 4px 4px;
    border: 0;
    width: 160px;
    align-self: center;
}

#mdl_org_individuals_indv .statusprogress-item-bar.INVITED,
#mdl_org_individuals_indv .statusprogress-item-bar.AUTHENTICATING {
    background-color: #FF9E16;
}

#mdl_org_individuals_indv .statusprogress-item-bar.ACTIVE {
    background-color: #45BA30;
}

#mdl_org_individuals_indv .statusprogress-item-bar.INVITEEXPIRED,
#mdl_org_individuals_indv .statusprogress-item-bar.DECLINED {
    background-color: #d91e18;
}

#mdl_org_individuals_indv .statusprogress-item-bar.INACTIVE,
#mdl_org_individuals_indv .statusprogress-item-bar.authenticating.INVITED,
#mdl_org_individuals_indv .statusprogress-item-bar.active.INVITED,
#mdl_org_individuals_indv .statusprogress-item-bar.active.AUTHENTICATING {
    background-color: #f1f2f2;
}

#mdl_org_individuals_indv .statusprogress-item-bar.active.INACTIVE {
    border-radius: 0;
}

#mdl_org_individuals_indv .statusoptions {
    margin-top: 16px;
    text-align: center;
}

#mdl_org_individuals_indv .statusoptions {
    display: flex;
}

#mdl_org_individuals_indv .modal-footer-share {
    display: flex;
    padding-left: 16px;
}