
#mdl_org_individuals_inviteindividual {
     width: 400px;
 }

#mdl_org_individuals_inviteindividual #firstname {
    margin-right: 8px;
}

#mdl_org_individuals_inviteindividual .individual_type {
    width: 100%;
}

#mdl_org_individuals_inviteindividual #accesscode {
    width: 180px;
}

#mdl_org_individuals_inviteindividual #usercode {
    margin-right: 8px;
    width: 180px;
}