
.crd_login .logo {
    display: flex;
    justify-content: center;
    margin-bottom: 24px;
}

.crd_login .logo img {
    width: 75%;
    height: 75%;
}

.crd_login #btn_createaccount {
    width: 100%;
}

#loginerror {
    display: flex;
    flex-direction: row;
}

#btn_createaccount {
    margin-top: 32px !important;
}

.crd_login .ui.button.btn_primary {
    background-color: #005687;
    color: #ffffff;
    border-color: #005687;
    font-weight: 700;
    opacity: 0.5;
    cursor: default;
}


.crd_login .btn_forgotpassword {
    color: #005687;
    font-weight: 500;
    font-style: italic;
    cursor: pointer;
    padding: 16px 0px 0px 0px;
}

.crd_login .password_icon {
    color: #29719a;
}

.crd_login .ui.button.btn_secondary {
    color: #005687;
    background-color: #ffffff;
    border: 1px solid #e5e7e8;
    font-weight: 700;
}

.crd_login .ui.button.btn_secondary:hover {
    color: #005687;
}

.crd_login .ui.primary.button,
.crd_login .ui.primary.buttons .button,
.crd_login .ui.loading.loading.loading.loading.loading.loading.button {
    background-color: #005687;
}

.crd_login .ui.button.btn_primary.btn_active:hover {
    background-color: #005687 !important;
}