#tab_indv_wallet_history .mobile_tab_name {
    display: none;
}

#tab_indv_wallet_history .td_generalobject {
    overflow-wrap: break-word;
    max-width: 10rem;
    white-space: normal;
}

#tab_indv_wallet_history .td_description {
    overflow-wrap: break-word;
    max-width: 10rem;
    white-space: normal;
}


/*  media queries --------------------------------------------------------------------------------------------------- */

@media only screen and (max-width: 1200px) {

    #tab_indv_wallet_history .td_transactionid {
        display: none;
    }
}

@media only screen and (max-width: 700px) {

    #tab_indv_wallet_history .td_transactionby {
        display: none;
    }

}

@media only screen and (max-width: 600px) {

    #tab_indv_wallet_history .td_transactionorg {
        display: none;
    }
}

@media only screen and (max-width: 500px) {

    #tab_indv_wallet_history .td_description {
        overflow-wrap: break-word;
        max-width: 10rem;
        white-space: normal;
      }
    
    #tab_indv_wallet_history .mobile_tab_name {
        padding-top: 5%;
        padding-bottom: 5%;
        display: block;
        text-align: center;
    }

}
