
/*  terrahub blue: #00adbb;
    terrahub green: #6cca98;
    terrahub black: #36484e;
    terrahub grey: #63666a;

    credivera navy: #29719a;

    black-medium: #717473;
    blacK-light: #8a8d8c;


    border: #e5e7e8;

    green: #69b536;
    yellow: #f2Ae02;
    red: #e0454f;
*/

/*  APP ------------------------------------------------------------------------------------------------------------- */

.app-container {
  /*display: grid;*/
  /*grid-template-rows: 60px auto;*/
  display: flex;
  flex-direction: column;
  height: 100vh;
}

.app-topmenu {
  display: flex;
  flex-direction: row;
  height: 72px;
  border-bottom: 1px solid #E4E8E7;
}

.app-topmenu-logo {
  display: flex;
  grid-area: topmenu-logo;
  padding: 0px 0px 0px 16px;
  align-items: center;
  margin-top: 2%;
  margin-bottom: 2%;
}

.app-topmenu-logo img {
  height: 45px;
}

.app-topmenu-menu {
  grid-area: topmenu-menu;
  display: flex;
  flex-grow: 1;
  align-items: center;
  padding-left: 24px;
}

.app-topmenu-menuitem {
  font-size: 14px;
  font-weight: 500;
  color: #717473;
  padding-right: 24px;
  cursor: pointer;
}

.app-topmenu-menuitem-active {
  font-weight: 500;
  color: #367aa0;
}

.app-topmenu-UTC {
  display: grid;
  grid-area: topmenu-UTC;
  grid-template-areas:  "app-topmenu-UTC-icon app-topmenu-UTC-time"
  "app-topmenu-UTC-icon app-topmenu-UTC-date";
  padding-right: 16px;
  white-space: nowrap;
  border-left: 1px solid #E4E8E7;
  padding-left: 16px;
}

.app-topmenu-UTC-icon {
  grid-area: app-topmenu-UTC-icon;
  width: 32px;
  padding-right: 12px;
}

.app-topmenu-UTC-icon img {
  width: 100%;
  height: 100%;
}

.app-topmenu-UTC-time {
  grid-area: app-topmenu-UTC-time;
  font-size: 14px;
  font-weight: 500;
  color: #717473;
  align-self: flex-end;
}

.app-topmenu-UTC-date {
  grid-area: app-topmenu-UTC-date;
  font-size: 11px;
  font-weight: 300;
  align-self: flex-start;
  line-height: 11px;
  padding-bottom: 4px;
}


.app-topmenu-loggedin {
  grid-area: topmenu-loggedin;
  display: grid;
  grid-template-areas:  "app-topmenu-loggedin-icon app-topmenu-loggedin-name"
  "app-topmenu-loggedin-icon app-topmenu-loggedin-logout";
  padding-right: 16px;
  white-space: nowrap;
  border-left: 1px solid #E4E8E7;
  padding-left: 16px;
}

.app-topmenu-loggedin-icon {
  grid-area: app-topmenu-loggedin-icon;
  width: 32px;
  padding-right: 12px;
}

.app-topmenu-loggedin-icon img {
  width: 100%;
  height: 100%;
}

.app-topmenu-loggedin-username {
  grid-area: app-topmenu-loggedin-name;
  font-size: 14px;
  font-weight: 500;
  color: #717473;
  align-self: flex-end;
  white-space: nowrap;
}

.app-topmenu-loggedin-logout {
  grid-area: app-topmenu-loggedin-logout;
  font-size: 13px;
  font-weight: 500;
  color: #508bac;
  cursor: pointer;
}

.app-topmenu-loggedin-logout:hover {
  color: #276c94;
}

.app-content {
  display: flex;
  flex-direction: row;
  flex-grow: 1;
}

.app-sidenav {
  border-top: 2px solid #F8F9F9;
  border-right: 1px solid #E4E8E7;
  padding: 0 16px 0px 16px;
  height: 100%;
  width: 200px;
  min-width: 200px;
  display: flex;
  flex-direction: column;
}

.app-sidenav-group {
  display: flex;
  flex-direction: column;
  flex-grow: 1;
  border-top: 1px dashed #e4e8e7;
  margin-bottom: 16px;
}

.app-sidenav-group:first-of-type {
  border-top: none;
}

.app-sidenav-group.setup {
  padding-top: 16px;
}

#sidenav_logout {
  display: none;
}

.app-sidenav-header {
  font-size: 12px;
  font-weight: 700;
  color: #C6CECC;
  padding: 16px 0 8px 0;
}

.app-sidenav-item {
  font-size: 14px;
  font-weight: 400;
  color: #8a8d8c;
  padding: 4px 0 4px 0;
  cursor: pointer;
  display: flex;
}

.app-sidenav-item_label{
  width: 27px;
  height: 17px;
}

.app-sidenav-item_icon {
  height: 17px;
  width: 17px;
  margin: 1px 12px 0 0;
  opacity: .75;
}

.app-sidenav-item_icon img {
  height: 100%;
  width: 100%;
}

.app-sidenav-item:hover {
  color: #29719a;
}

.app-sidenav-item.active {
  border-right: 4px solid #29719a;
  margin-right: -16px;
  color: #29719a;
}

.app-sidenav-item_icon.active {
  filter: invert(37%) sepia(6%) saturate(7193%) hue-rotate(150deg) brightness(60%) contrast(100%);
}

.app-sidecontent {
  background-color: #eef1f0;
  height: 100%;
  display: flex;
  flex-direction: column;
  flex-grow: 1;
  background-image: url("data:image/svg+xml, %3Csvg%20xmlns%3D'http%3A%2F%2Fwww.w3.org%2F2000%2Fsvg'%20width%3D'56'%20height%3D'100'%3E%0A%3Crect%20width%3D'56'%20height%3D'100'%20fill%3D'%23eef0f0'%2F%3E%0A%3Cpath%20d%3D'M28%2066L0%2050L0%2016L28%200L56%2016L56%2050L28%2066L28%20100'%20fill%3D'none'%20stroke%3D'%23f3f4f4'%20stroke-width%3D'2'%2F%3E%0A%3Cpath%20d%3D'M28%200L28%2034L0%2050L0%2084L28%20100L56%2084L56%2050L28%2034'%20fill%3D'none'%20stroke%3D'%23f4f4f4'%20stroke-width%3D'2'%2F%3E%0A%3C%2Fsvg%3E");
}



/*  CONTENT --------------------------------------------------------------------------------------------------------- */

.content {
  display: flex;
  flex-direction: column;
  height: 100%;
}

.content-topmenu {
  background-color: #F8F9F9;
  display: flex;
  align-items: center;
  padding: 11px 0 13px 16px;
  border-bottom: 1px solid #E4E8E7;
  border-top: 2px solid #F4F6F5;
  font-size: 16px;
  font-weight: 500;
  color: #4b4f4e;
}

.content-topmenu img {
  width: 24px;
  height: 24px;
  margin-right: 8px;
}

.content-sidemenu-wrapper {
  display: flex;
  flex-direction: row;
  flex-grow: 1;
}

.content-sidemenu {
  width: 210px;
  height: 100%;
  border-top: 2px solid #F8F9F9;
  border-left: 2px solid #F8F9F9;
  border-right: 1px solid #E4E8E7;
  background-color: #ffffff;
  display: flex;
  flex-direction: column;
}

.content-sidemenu-item {
  display: flex;
  flex-direction: column;
  padding: 16px 8px;
  border-bottom: 1px solid #eaeaea;
  cursor: pointer;
}

.content-sidemenu-item-active {
  border-right: 4px solid #29719a;
  color: #29719a;
}


.content-sidemenu-item:hover {
  color: #29719a;
  background-color: #fbfbfb;
}

.content-sidemenu-item_header {
  font-weight: 500;
  font-size: 14px;
}

.content-sidemenu-item_subheader {
  padding-top: 4px;
  font-weight: 400;
  color: #bebebf;
  line-height: 14px;
}

.content-wrapper {
  padding: 24px;
  display: flex;
  flex-direction: column;
}

.content-sidemenu-wrapper .content-wrapper {
  flex-grow: 1;
}

.content-card {
  width: 100%;
  display: grid;
  grid-template-rows: 60px minmax(min-content, max-content);
  background-color: #fff;
  border: 1px solid #e5e7e8;
  border-radius: 4px 4px 0 0;
}

.content-card-hide {
  display: none;
}

.content-card-noheader {
  width: 100%;
  display: grid;
  background-color: #fff;
  border: 1px solid #e5e7e8;
  border-radius: 4px;
}

.content-card-header {
  font-size: 16px;
  font-weight: 700;
  color: #49595f;
  align-items: center;
  padding: 0 24px 4px;
  display: flex;
  flex-direction: row;
  border-bottom: 1px dashed #E8E9E9;
}

.content-card-header-title {
  flex-grow: 1;
}

.content-card-header .ui.button {
  background-color: #ffffff;
  border: 1px solid #E8E9E9;
  font-size: 11px;
  font-weight: 500;
  color: #676C6B;
  padding: 10px 16px 10px 16px;
  margin: 3px 0 0 0;
}

.content-card-subheader {
  font-size: 16px;
  font-weight: 700;
  color: #717473;
  padding-bottom: 16px;
  display: flex;
}

.content-card-subheader_text {
  flex-grow: 1;
}

.content-card-subheader_icon {
  cursor: pointer;
  width: 19px;
  height: 19px;
  margin-left: 8px;
}

.content-card-subheader_icon:first-of-type {
  margin-left: 0;
}

.content-card-subheader_icon img {
  width: 100%;
  height: 100%;
}

.content-card-content {
  padding: 16px 24px;
  display: flex;
  flex-direction: column;
}

.detail-spacer {
  height: 4px;
}

.detail-item {
  display: flex;
  height: 18px;
}

.detail-item-label {
  font-size: 11px;
  font-weight: 500;
  color: #cfd3d5;
  align-self: center;
  width: 110px;
}

.detail-item-text {
  align-self: center;
}


.content-card-options {
  display: flex;
  flex-direction: row;
  justify-content: flex-end;
  padding: 16px 0 16px 0;
  margin-bottom: 8px;
  border-bottom: 1px dashed #ebebeb;
}

.content-card-gridcontent {
  display: contents;
}

.content-filter {
  padding: 24px 8px 8px 24px;
  display: flex;
  justify-content: flex-end;
}

.content-filter-archive {
    display: flex;
}

.content-filter-item.item-pagination {
  flex-grow: 1;
}

.content-card-footer {
  border-top: 1px solid #E8E9E9;
  height: 56px;
  background-color: #F8F9F9;
  display: grid;
  grid-template-columns: auto auto;
  align-items: center;
}

.content-card-footer-lastmodified {
  opacity: 0.75;
  display: grid;
  grid-template-columns: 22px auto;
  padding-left: 8px;
  align-items: center;
}

.content-card-footer-lastmodified_text {
  font-size: 11px;
  font-weight: 500;
  color: #676C6B;
  line-height: 11px;
}

.content-card-footer-lastmodified_icon {
  width: 19px;
  height: 19px;
  float: left;
  cursor: pointer;
  opacity: .8;
}

.content-card-footer-lastmodified_icon img {
  height: 100%;
  width: 100%;
}


.content-card-footer-buttons {
  display: flex;
  justify-content: flex-end;
  padding-right: 8px;
}

.content-card-footer-buttons .ui.button {
  background-color: #ffffff;
  border: 1px solid #E8E9E9;
  font-size: 11px;
  font-weight: 500;
  color: #676C6B;
  padding: 10px 16px 10px 16px;
}


.stat-card {
  padding: 12px;
  background-color: #fff;
  border: 1px solid #e5e7e8;
  border-radius: 4px;
  margin-right: 8px;
}

.stat-card-header {
  font-size: 15px;
  font-weight: 500;
  color: #4b4f4e;
  padding-bottom: 16px;
  display: flex;
  align-items: center;
}

.stat-card-header img {
  width: 22px;
  height: 22px;
  margin: -1px 8px 0 0;
}

.stat-card-header .ui.button {
  padding: 8px 0 !important;
}

.stat-card-stat {
  font-size: 96px;
  font-weight: 500;
  line-height: 96px;
  letter-spacing: -4px;
  color: #29719a;
  text-align: center;
}

.stat-card-text {
  font-size: 11px;
  font-weight: 500;
  line-height: 20px;
  color: #cfd3d5;
  text-align: center;
}


/*  tabs -------------------------------------------------------------------------------------------------------------*/

.content-tabs {
  display: flex;
  flex-direction: row;
  padding: 8px 0 0 8px;
  margin-bottom: -1px;
}

.content-tabs-item {
  background-color: #fff;
  border: 1px solid #e5e7e8;
  border-bottom: none;
  border-radius: 4px 4px 0 0;
  padding: 12px 24px;
  font-size: 14px;
  font-weight: 500;
  color: #49595f;
  margin-right: 4px;
  cursor: pointer;
}

.content-tabs-item img {
  width: 19px;
  height: 19px;
  margin: 0px 8px -5px -6px;
}

.content-tabs-item-active {
  z-index: 1;
}



/*  modals -----------------------------------------------------------------------------------------------------------*/

.modal-header {
  font-size: 20px;
  font-weight: 700;
  letter-spacing: -.25px;
  display: flex;
  align-items: center;
  padding: 16px;
  border-bottom: 1px dashed #ebebeb;
  height: 56px;
}

.modal-header-title {
  padding-right: 48px;
}

.modal-header-tabs {
  display: flex;
  flex-grow: 1;
}

.modal-header-tabs-item {
  font-size: 14px;
  font-weight: 600;
  letter-spacing: .1px;
  color: #838e91;
  padding: 0 16px 0 16px;
  cursor: pointer;
  display: flex;
}

.modal-header-tabs-item:hover {
  color: #29719a;
}

.modal-header-tabs-item.active {
  border-top: 4px solid #3c7ea3;
  margin-top: -21px;
  padding-top: 17px;
  color: #3c7ea3;
}

.modal-header-close {
  opacity: .75;
  cursor: pointer;
  margin: -16px -16px -16px 0;
  padding: 14px;
}

.modal-header-close:hover {
  opacity: 1;
}

.modal-header-close img {
  width: 18px;
  height: 18px;
}

.modal-content {
  padding: 16px;
  font-size: 13px;
  line-height: 17px;
}

.modal-tab-hide {
  display: none;
}

.modal-content-instructions {
  font-size: 14px;
  color: #4B4F4E;
  line-height: 16px;
  padding-bottom: 24px;
}

.modal-footer {
  /*border-top: 1px solid #E8E9E9;*/
  height: 56px;
  background-color: #F8F9F9;
  display: flex;
  flex-direction: row;
  align-items: center;
  background-image: url("data:image/svg+xml, %3Csvg%20xmlns%3D'http%3A%2F%2Fwww.w3.org%2F2000%2Fsvg'%20width%3D'56'%20height%3D'100'%3E%0A%3Crect%20width%3D'56'%20height%3D'100'%20fill%3D'%23F8F9F9'%2F%3E%0A%3Cpath%20d%3D'M28%2066L0%2050L0%2016L28%200L56%2016L56%2050L28%2066L28%20100'%20fill%3D'none'%20stroke%3D'%23fdfdfd'%20stroke-width%3D'2'%2F%3E%0A%3Cpath%20d%3D'M28%200L28%2034L0%2050L0%2084L28%20100L56%2084L56%2050L28%2034'%20fill%3D'none'%20stroke%3D'%23fdfdfd'%20stroke-width%3D'2'%2F%3E%0A%3C%2Fsvg%3E");

}

.modal-actionbar {
  padding: 12px 16px 12px 16px;
  display: flex;
  flex-direction: row;
  align-items: center;
}

.modal-archive {
  margin-top: 16px;
  background-color: #29719a;
  color: #ffffff;
  font-weight: 600;
  display: flex;
  flex-direction: row;
  align-items: center;
  cursor: pointer;
}

.modal-archive-icon {
  padding: 8px 16px;
}

.modal-archive-icon img {
  width: 20px;
  height: 20px;
}

.modal-archive-text {
  line-height: 13px;
}

.modal-footer-archive {
  display: flex;
  padding-left: 16px;
}

.modal-footer-share {
  display: flex;
  padding-left: 16px;
}

.modal-footer-buttons {
  display: flex;
  flex-grow: 1;
  justify-content: flex-end;
  padding-right: 8px;
}


.modal-footer-buttons .ui.button {
  background-color: #ffffff;
  border: 1px solid #E8E9E9;
  font-size: 11px;
  font-weight: 500;
  color: #676C6B;
  padding: 10px 16px 10px 16px;
}

/*  popup admin ------------------------------------------------------------------------------------------------------*/

.pop_admin-item {
  font-weight: 500;
  color: #717473;
  font-size: 13px;
  padding: 12px 48px 12px 24px;
  display: flex;
  cursor: pointer;
}

.pop_admin-item_icon {
  width: 17px;
  height: 17px;
  margin-top: 1px;
  margin-right: 16px;
}

.pop_admin-item_icon img {
  width: 100%;
  height: 100%;
}

.pop_admin-item:hover {
  background-color: #f8f8f8;
  color: #494847;
}

.pop_admin-item:first-of-type {
  border: none;
}

.pop_status {
  display: flex;
  flex-direction: row;
  padding: 4px 12px 4px 12px;
}

.pop_status img {
  width: 20px;
  height: 20px;
  margin-right: 8px;
}


/*  message ----------------------------------------------------------------------------------------------------------*/

.message {
  border: 1px solid #e5e7e8;
  border-radius: 3px;
  padding: 8px;
  margin-bottom: 24px;
  display: flex;
  flex-direction: row;
  align-items: center;
}

.message_icon {
  padding: 0 12px 0 4px;
  display: flex;
}

.message_icon img {
  width: 24px;
  height: 24px;
  opacity: .8;
}

.message_text_wrapper {
  display: flex;
  flex-direction: column;
}

.message_text {
  font-size: 13px !important;
  line-height: 16px !important;
}

.message_text.cause {
  font-weight: 400 !important;
  padding-top: 4px;
  padding-bottom: 4px;
}

.message_text.resolution {
  font-weight: 500 !important;
  padding-top: 4px;
  padding-bottom: 4px;
}

.message.warning {
  color: #e0454f;
  border-color: #ed9399;
  background-color: #fef8f8;
}

.message.info {
  color: #1e6f9e;
  border-color: #3589b9;
  background-color: #f8fdfd;
}

.message.success {
  color: #69b536;
  border-color: #a8d48a;
  background-color: #f9fcf7;
}


/*  semantic ui datepicker -------------------------------------------------------------------------------------------*/

.ui.segment.clndr-calendars-segment.clndr-floating {
  font-family: "Open Sans", sans-serif !important;
  padding-top: 10px !important;
  padding-bottom: 12px !important;
  font-size: 13px !important;
  line-height: 14px !important;
  font-weight: 500 !important;
  color: #4b4f4e !important;
  border-color: #ebebeb !important;
  box-shadow: rgba(34, 36, 38, 0.15) 0px 2px 3px 0px;
  border-radius: 3px !important;
  min-height: unset !important;
  min-width: 60px !important;
}

.clndr-days {
  border-radius: 3px !important;
  border-color: #ebebeb;
}

.clndr-cell {
  font-weight: 400;
  display: flex !important;
  align-items: center !important;
  justify-content: center !important;
}

.clndr-cell:hover {
  color: #29719a;
  background-color: #effafb;
}

.clndr-cell-disabled:hover {
  color: unset;
  background-color: unset;
}

.clndr-cell-selected {
  font-weight: 700;
  background-color: #29719a;
}


/*  semantic ui components -------------------------------------------------------------------------------------------*/

/*  form elements ----------------------------------------------------------------------------------------------------*/

.ui.form .fields {
  margin: 0;
}

.ui.form .fields>.field {
  padding: 0 0 16px 0 !important;
}

.ui.form .field>label {
  font-size: 11px !important;
  font-weight: 500;
  color: #cfd3d5 !important;
  margin: 0 0 3px 0 !important;
}

.ui.form .field.field input,
.ui.form .field.field textarea {
  font-family: "Roboto", sans-serif !important;
  padding-top: 9px !important;
  padding-bottom: 9px !important;
  font-size: 13px !important;
  line-height: 18px !important;
  font-weight: 500 !important;
  color: #4b4f4e !important;
  border-color: #ebebeb !important;
  box-shadow: 0 0 0 100px #fbfbfb inset!important;
  border-radius: 3px !important;
}

.ui.form .field.field input:-webkit-autofill::first-line {
  font-size: 13px !important;
  font-weight: 500 !important;
  color: #4b4f4e !important;
}

.ui.form .field.field input:-webkit-autofill,
.ui.form .field.field input:-webkit-autofill:hover,
.ui.form .field.field input:-webkit-autofill:focus,
.ui.form .field.field textarea:-webkit-autofill,
.ui.form .field.field textarea:-webkit-autofill:hover,
.ui.form .field.field textarea:-webkit-autofill:focus,
.ui.form .field.field select:-webkit-autofill,
.ui.form .field.field select:-webkit-autofill:hover,
.ui.form .field.field select:-webkit-autofill:focus {
  -webkit-box-shadow: 0 0 0px 100px #fbfbfb inset !important;
  -webkit-text-fill-color: #4b4f4e !important;
  border-color: #ebebeb !important;
}

.form-spacer {
  width: 8px;
}

/*  label ------------------------------------------------------------------------------------------------------------*/

label {
  font-size: 11px !important;
  font-weight: 500;
  color: #cfd3d5 !important;
  margin: 0 0 3px 0 !important;
  display: flex;
  width: 100%;
}


/*  inputs -----------------------------------------------------------------------------------------------------------*/

.ui.input>input {
  padding-top: 10px !important;
  padding-bottom: 12px !important;
  font-size: 13px !important;
  line-height: 16px !important;
  font-weight: 500 !important;
  color: #4b4f4e !important;
  border-color: #ebebeb !important;
  box-shadow: 0 0 0 100px #fbfbfb inset!important;
  border-radius: 3px !important;
  min-height: unset !important;
}


.ui.input>input::placeholder {
  font-size: 13px !important;
  font-weight: 500 !important;
  color: #c6cacb !important;
}

.ui.action.input:not([class*="left action"])>.button:last-child,
.ui.action.input:not([class*="left action"])>.buttons:last-child>.button,
.ui.action.input:not([class*="left action"])>.dropdown:last-child {
  border-radius: 0 3px 3px 0 !important;
  border: 1px solid #e5e7e8 !important;
  border-left: none !important;
  color: #29719a;
  font-weight: 700;
}

.ui.action.input:not([class*="left action"])>input {
  border-radius: 3px 0 0 3px !important;
}

.ui.input.error > input, div.field.error > .ui.input > input {
  background-color: #fff6f6 !important;
  border-color: #e0b4b4 !important;
  color: #9f3a38 !important;
  box-shadow: none !important;
}

/*  buttons ----------------------------------------------------------------------------------------------------------*/

.ui.action.input>.button {
  background-color: #ffffff !important;
  border: 1px solid #BBBFBE !important;
  font-size: 11px;
  font-weight: 700;
  color: #676C6B;
}

.ui.button {
  font-family: "Open Sans", sans-serif !important;
  border-radius: 3px !important;
  margin: 0 0 0 4px !important;
  font-size: 11px;
  padding: 10px 16px 10px 16px;
}

.ui.button:first-of-type {
  margin: 0 0 0 0 !important;
}

.ui.button.btn_secondary {
  color: #3c7ea3;
  background-color: #ffffff;
  border: 1px solid #e5e7e8;
  font-weight: 700;
}

.ui.button.btn_secondary:hover {
  color: #225d7e;
}

.ui.button.btn_primary {
  background-color: #29719a;
  color: #ffffff;
  border-color: #29719a;
  font-weight: 700;
  opacity: 0.5;
  cursor: default;
}

.ui.button.btn_primary.btn_active {
  opacity: 1;
  cursor: pointer;
}

.ui.button.btn_primary.btn_active:hover {
  background-color: #205777;
  border-color: #205777;
}

.ui.button.btn_secondary.btn_disabled {
  background-color: #E4E5E4 !important;
  border-color: #B1B3B2 !important;
  color: #717473 !important;
  cursor: default !important;
}

.ui.primary.button,
.ui.primary.buttons .button,
.ui.loading.loading.loading.loading.loading.loading.button {
  font-family: "Roberto", sans-serif !important;
  background-color: #29719a;
  color: #ffffff;
  text-shadow: none;
  background-image: none;
}

.ui.primary.button:hover,
.ui.primary.buttons .button:hover {
  background-color: #29719a;
}

.ui.button.btn_tertiary {
  display: flex;
  flex-direction: row;
  align-items: center;
  background-color: transparent;
  border: none;
  font-size: 11px;
  font-weight: 700;
  color: #3c7ea3;
  padding: 8px 16px 8px 0;
  width: fit-content;
  text-align: left;
}

.ui.button.btn_tertiary:hover {
  color: #25678c;
}

.ui.button.btn_tertiary .btn_icon {
  width: 16px;
  height: 16px;
  margin: -4px 6px -4px 0;
}

.ui.button.activestate {
  background-color: #29719a;
  border-color: #29719a;
  color: #ffffff;
  font-weight: 700;
}

.ui.button.btn_icononly {
  border: 1px solid #e9eaeb;
  cursor: pointer;
  background-color: #ffffff;
  padding: 9px 18px;
  height: 38px;
}

.ui.button.btn_icononly .btn_icon {
  margin-right: -8px;
}

.ui.button .btn_icon {
  width: 16px;
  height: 16px;
  margin: -4px 10px -3px -6px;
}


/*  table ------------------------------------------------------------------------------------------------------------*/

.tablewrapper {
  overflow-x: auto;
  overflow-y: hidden;
  white-space: nowrap;
  margin-bottom: 16px;
}

.td_overflow {
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
  max-width: 19rem;
}

.emptytable {
  background-color: #fdfdfd;
  display: flex;
  width: 100%;
  border-bottom: 1px solid #ebebeb;
  padding: 6px 0 6px 0;
  align-items: center;
  justify-content: center;
  color: #cdcecd;
}

.emptytable img {
  width: 18px;
  height: 18px;
  margin-right: 8px;
}

.tablewrapper .ui.table {
  margin: 16px 0 0 0 !important;
}

.ui.table {
  border: none;
  background: none;
  font-size: 13px;
  font-weight: 400;
  color: #4b4f4e;
  margin: 16px 0 !important;
  border-radius: 0 !important;
}

.ui.table thead th {
  background: none !important;
  font-size: 11px !important;
  font-weight: 500 !important;
  line-height: 20px !important;
  color: #cfd3d5 !important;
  padding: 4px 8px 0 8px !important;
  cursor: pointer !important;
  height: 31px !important;
}

.ui.table thead th img {
  height: 18px;
}

.tbl_loading {
  opacity: .5;
}

.ui.table tr {
  cursor: pointer;
  height: 31px;
}

.ui.table tbody tr:hover {
  background-color: #f6fcfd;
  color: #29719a;
}

.ui.table tr td {
  border-top: 1px solid #ebebeb;
}

.ui.compact.table td {
  padding: 6px 8px !important;
}

.ui.sortable.table thead th {
  border-left: none !important;
}

button.td_filter_button {
  background-color: transparent;
  border: none;
  color: #cfd3d5;
  cursor: pointer;
  display: inline-block;
  height: 100%;
  padding: 3px 6px;
}

button.td_filter_button.filter_applied {
  color: #36484e;
}

button.td_filter_button:focus {
  outline: none;
}

.td_icon {
  width: 36px;
  cursor: pointer;
}

.td_icon img {
  width: 20px;
  height: 20px;
  margin: -1px 0 -4px 0;
}

.tbl_credentialicon {
  padding: 6px 4px 6px 8px !important;
  display: flex;
}

.tbl_credentialicon div {
  margin: 0 4px 0 0;
}

.tbl_credentialicon img {
  width: 17px;
  height: 17px;
  margin: 1px 0 -3px 0;
}

.td_icon_center {
  display: flex;
  justify-content: center;
  align-items: center;
  height: 28.5px;
}

.td_icon_remove{
  text-align :center;

}

.ui.sortable.table thead th.sorted.td_icon_center::after {
  margin-bottom: 10px;
}

.ui.sortable.table thead th.sorted.td_icon_remove::after {
  margin-bottom: 10px;
}

.td_icon_center div {
  margin: 0 0 -5px 0;
}

.td_icon_remove img {
  width: 18px;
  height: 18px;
}

.td_icon_center img {
  width: 18px;
  height: 18px;
}

.td_icon_incell {
  width: 20px;
  height: 20px;
  margin: -1px 8px -4px 0;
}

.td_icon_incell img {
  width: 20px;
  height: 20px;
  margin: -1px 8px -1px 0;
}

.td_icon_spacer {
  width: 20px;
  height: 20px;
  margin: -1px 8px -4px 0;
}

.td_transactionid {
  color: #b0b2b1;
  width: 264px;
}

.tbl_checkmark {
  height: 18px;
  width: 18px;
  float: left;
  margin-right: 8px;
}

.tbl_checkmark img {
  width: 100%;
  height: 100%;
}


/*  checkbox ---------------------------------------------------------------------------------------------------------*/

.ui.radio.checkbox {
  min-height: 15px;
  margin-right: 16px !important;
}

.ui.radio.checkbox label {
  padding-left: 20px !important;
  font-size: 13px !important;
  font-weight: 400 !important;
  color: #676C6B !important;
}

.ui.selection.multiple.dropdown {
  padding-top: 4px !important;
  padding-bottom: 4px !important;
}

.ui.toggle.checkbox input:checked~.box:before,
.ui.toggle.checkbox input:checked~label:before {
  background-color: #29719a !important;
}

.ui.toggle.checkbox input:focus:checked~.box:before,
.ui.toggle.checkbox input:focus:checked~label:before {
  background-color: #29719a !important;
}

.ui.toggle.checkbox label {
  font-size: 13px !important;
  line-height: 18px !important;
  font-weight: 500 !important;
  color: #8a8d8c !important;
}

.ui.toggle.checkbox input:checked~label,
.ui.toggle.checkbox input:focus:checked~label {
  font-size: 13px !important;
  line-height: 18px !important;
  font-weight: 500 !important;
  color: #4b4f4e !important;
}

.ui.toggle.checkbox.th_toggle input:checked~.box:before,
.ui.toggle.checkbox.th_toggle input:checked~label:before {
  opacity: .95;
  color: #4b4f4e !important;
}

.ui.toggle.checkbox.th_toggle input:focus:checked~.box,
.ui.toggle.checkbox.th_toggle input:focus:checked~label {
  opacity: .95;
  color: #4b4f4e !important;
}

.ui.checkbox input:checked~.box:after,
.ui.checkbox input:checked~label:after {
  opacity: .95;
  color: #4b4f4e !important;
}

/*  dropdown ---------------------------------------------------------------------------------------------------------*/

.ui.selection.dropdown {
  font-family: "Roboto", sans-serif !important;
  padding-top: 10px !important;
  padding-bottom: 12px !important;
  font-size: 13px !important;
  line-height: 14px !important;
  font-weight: 500 !important;
  color: #4b4f4e !important;
  border-color: #ebebeb !important;
  box-shadow: 0 0 0 100px #fbfbfb inset!important;
  border-radius: 3px !important;
  min-height: unset !important;
  min-width: 60px !important;
}

.ui.search.dropdown>input.search {
  left: 0 !important;
}

.ui.selection.dropdown>.delete.icon,
.ui.selection.dropdown>.dropdown.icon,
.ui.selection.dropdown>.search.icon {
  line-height: unset;
  padding: 11px 7px;
}

.ui.selection.active.dropdown .menu {
  border-color: #ebebeb !important;
}

.ui.selection.dropdown .menu>.item {
  padding: 8px 16px !important;
}

.ui.dropdown .menu .active.item {
  font-weight: 700 !important;
  color: #4b4f4e !important;
}

.ui.dropdown .menu>.item {
  color: #4b4f4e;
  font-size: 13px;
  font-weight: 400;
}

.ui.multiple.search.dropdown > input.search {
  padding: 2px !important;
  margin: .14285714rem .28571429rem .14285714rem 0 !important;
}

/*  legend ----------------------------------------------------------------------------------------------------*/

.legend-footer {
  min-height: 50px;
  display: flex;
  padding: 3%;
}

.legend-item {
  display: flex;
  margin-right: 20px;
}

.legend-item img {
  height: 17px;
}

.legend-item-text {
  margin-left: 3px;
  font-weight: 400;
  font-size: 13px;
  opacity: 0.5;
}

.legend_footer {
  background-image: url("data:image/svg+xml, %3Csvg%20xmlns%3D'http%3A%2F%2Fwww.w3.org%2F2000%2Fsvg'%20width%3D'56'%20height%3D'100'%3E%0A%3Crect%20width%3D'56'%20height%3D'100'%20fill%3D'%23F8F9F9'%2F%3E%0A%3Cpath%20d%3D'M28%2066L0%2050L0%2016L28%200L56%2016L56%2050L28%2066L28%20100'%20fill%3D'none'%20stroke%3D'%23fdfdfd'%20stroke-width%3D'2'%2F%3E%0A%3Cpath%20d%3D'M28%200L28%2034L0%2050L0%2084L28%20100L56%2084L56%2050L28%2034'%20fill%3D'none'%20stroke%3D'%23fdfdfd'%20stroke-width%3D'2'%2F%3E%0A%3C%2Fsvg%3E") !important;
}

/*  progress bars ----------------------------------------------------------------------------------------------------*/

.ui.progress {
  background: #ffffff !important;
  border: 1px solid #ebebeb !important;
  box-shadow: 0 0 0 100px #fbfbfb inset!important;
  border-radius: 3px !important;
}

.ui.progress .bar {
  background: #69b536 !important;
  border-radius: 2px !important;
  height: 100%;
}


/*  pagination -------------------------------------------------------------------------------------------------------*/

.pg_group {
  display: flex;
  justify-content: flex-end;
}

.pg_group .ui.form .fields>.field {
  padding: 0 0 0 0 !important;
}

.pg_group .pg_results {
  font-size: 13px !important;
  font-weight: 400 !important;
  line-height: 20px;
  color: #4b4f4e !important;
  padding: 8px 8px 0 0;
  display: flex;
}

.pg_group .pg_results_emph {
  padding: 0 3px 0 3px;
  font-weight: 700;
  color: #4b4f4e;
}

.pg_group .pg_show {
  width: 60px;
}

.pg_group .ui.pagination.menu {
  box-shadow: none !important;
  border: 1px solid #ebebeb !important;
  border-radius: 3px !important;
  height: 38px;
  margin: 0 0 0 4px;
}

.pg_group .ui.pagination.menu .item {
  font-size: 13px !important;
  line-height: 14px !important;
  font-weight: 500 !important;
  color: #4b4f4e !important;
  padding: 12px 16px 12px !important;
  min-width: unset !important;
}

.pg_group .ui.pagination.menu .item:focus {
  outline: none !important;
}

.pg_group .ui.pagination.menu .active.item {
  background-color: #fbfbfb !important;
  color: #508bac !important;
}

.pg_group .pg_jump {
  width: 80px;
}

.pg_group #btn_refresh {
  margin: 0 0 0 4px !important;
}

.pg_group .pg_refresh:hover,
.pg_group .btn_download:hover {
  background: rgba(0,0,0,.03);
}

.pg_group .pg_refresh.pg_loading {
  opacity: 0.5;
}

.pg_group .pg_download {
  margin: 25px 0px 16px 4px;
}


/*  popup -----------------------------------------------------------------------------------------------------------*/

.ui.popup {
  padding: 8px 0 8px 0 !important;
  border-color: #ebebeb !important;
  font-size: 13px !important;
  font-weight: 500 !important;
  color: #4b4f4e !important;
}

.ui.popup:before {
  box-shadow: -1px -1px 0 0 #e4e4e3 !important;
}





@media only screen and (max-width: 1000px) {

  .app-container {
    /*grid-template-rows: 100px auto;*/
  }

  /*test comment*/

  .app-topmenu {
    height: 80px;
    /*grid-template-columns: 1fr 1fr;*/
    /*grid-template-areas:  'topmenu-logo topmenu-loggedin'*/
                          /*'topmenu-menu topmenu-menu';*/
  }

  .app-topmenu-menu {
    /*justify-content: center;*/
  }

  .app-content {
    /*grid-template-columns: 1fr;
    grid-template-rows: 40px auto;*/
    flex-direction: column;
  }

  .app-sidenav {
    width: 100%;
    height: unset;
    padding: 8px;
    border-right: none;
    border-top: none;
    border-bottom: 1px solid #E4E8E7;
  }

  .app-sidenav-group {
    display: flex;
    justify-content: center;
  }

  .app-sidenav-header {
    display: none;
  }

  .app-sidenav-item {
    padding: 8px;
  }

  .content-wrapper {
    padding: 12px
  }

}

@media only screen and (max-width: 800px) {

  .app-topmenu-logo {
    flex-grow: 1;
  }

  .app-topmenu-menu {
    display: none;
  }

}

@media only screen and (max-width: 650px) {

  #app-topmenu-menuitem_organization {
    display: none;
  }

  #app-sidenav-group_organization {
    display: none;
  }
}


@media only screen and (max-width: 615px) {

  .ui.form .field.field input {
    background-color: rgba(250,250,250,0) !important;
  }

}


@media only screen and (max-width: 500px) {

  #sidenav_logout {
    display: flex;
  }

  .app-topmenu {
    border-bottom: none;
  }

  .app-topmenu-logo {
    justify-content: center;
  }

  .app-topmenu-UTC {
    display: none;
  }

  .app-topmenu-loggedin {
    display: none;
  }

  .app-sidenav-item_icon {
    margin: 0 4px 0 0;
  }

  .content-wrapper {
    padding: 6px
  }

  .app-sidenav-item.active {
    margin-right: 0px;
  }

}


/*  GOOGLE CHARTS --------------------------------------------------------------------------------------------------- */

svg > g > g.google-visualization-tooltip {
  pointer-events: none;
}